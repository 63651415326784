<!--
 * @Author: zhai
 * @Description: 其他信息
-->
<template>
    <div>
        <!-- 居住信息 -->
        <LivingInformation ref="LivingInformation" :userForm="currentItem" style="margin-bottom: 20px"/>
        <!-- 语言能力 -->
        <Language ref="Language" :userForm="currentItem.language_detail" style="margin-bottom: 20px"/>
        <!-- 专业技能行业 -->
        <Industry ref="Industry" :userForm="currentItem.profession" style="margin-bottom: 20px"/>
        <!-- 辅助资料 -->
        <Auxiliary ref="Auxiliary" :userForm="currentItem.question" style="margin-bottom: 20px"/>
    </div>
</template>

<script lang="javascript">
import LivingInformation from "./C/LivingInformation.vue";
import Language from "./C/Language.vue";
import Industry from "./C/Industry.vue";
import Auxiliary from "./C/Auxiliary.vue";
import { postClientInfo } from '../../../../api/gifted'
export default {
    name: "Other",
    components: {
        LivingInformation,
        Language,
        Industry,
        Auxiliary
    },
    props: {
        currentItem: {
            type: Object,
            default: () => {
                return {};
            },
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
        };
    },
    computed: {
    },
    created() {
        console.log(this.currentItem)
    },
    methods: {
        // 判断是保存还是下一步
        checkInfo(type) {
            if (type === 1) {
                this.$refs['Industry'].save();
                return this.hanledSaveQuestion(type);
            } else {
                const LivingInFun = this.$refs['LivingInformation'].submitForm();
                const LanguageFun = this.$refs['Language'].submitForm();
                const IndustryFun = this.$refs['Industry'].submitForm();
                const AuxiliaryFun = this.$refs['Auxiliary'].submitForm();
                Promise.all([LivingInFun, LanguageFun, IndustryFun, AuxiliaryFun]).then(() => {
                    //全部验证通过就会走这里
                    console.log(1111)
                    this.hanledSaveQuestion(type)
                }, err => {
                    this.$utils.scrollIntoError(this)
                })
            }
        },
        // 发起保存动作
        hanledSaveQuestion(parmas) {
            this.currentItem.next = parmas - 1;
            this.$emit('update:loading', true)
            postClientInfo(this.currentItem).then((res) => {
                if (res.code === 200) {
                    // 切换步骤保存 下一步的时候才设置
                    if (parmas === 2) {
                        this.$message.success("提交成功！");
                        this.$emit('next')
                    } else {
                        this.$message.success("保存成功！");
                        this.$emit('update')
                    }
                }
            }).finally(() => {
                this.$emit('update:loading', false)
            });
        },
    },
};
</script>

<style lang="less" scoped>
</style>
