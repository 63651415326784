<!--
 * @Author: Jacob.Zhang
 * @Description: 客户端 - 引导页
-->
<template>
    <div class="guide-box">
        <div v-loading="isLoading" class="guide-content">
            <div class="guide-bg">
                <img :src="readyPage === 1 ? guide1 : guide2">
                <!-- <i class="el-icon-close close-btn" @click="confirm" /> -->
            </div>
            <div class="word-content">
                <!-- 第一页 -->
                <template v-if="readyPage === 1">
                    <p>您好！欢迎来到香港优才申请系统，请按照<strong>实际情况</strong>填写；</p>
                    <p>1.如您填写途中想离开系统，可以<strong>点击下方的保存，</strong>在您有空闲的时间再继续进行填写；</p>
                    <p>2.此次填写将花费您<strong>30-40分钟，</strong>系统将根据您所填写的信息，<strong>形成详细资料清单</strong>，一起为香港身份起航吧~</p>
                </template>
                <!-- 第二页 -->
                <template v-if="readyPage === 2">
                    <p>您也可以事先准备您自己、配偶及子女（如有）的材料在<strong>电脑中</strong>，系统会自动识别信息填写，可为您<strong>节省40%</strong>的信息填写时间；</p>
                    <p><strong>准备材料：</strong></p>
                    <div class="check-info">
                        <span><i class="el-icon-check"></i>身份证正反面照片</span>
                        <span><i class="el-icon-check"></i>护照（如有）</span>
                        <span><i class="el-icon-check"></i>港澳通行证正反面照片</span>
                        <span><i class="el-icon-check"></i>简历/工作总结</span>
                    </div>
                    <div class="msg">
                        <span class="msg-left">温馨提示：</span><span>请确认您所上传的资料真实有效，避免因递交资料存在问题给您造成不便。</span>
                    </div>
                    <div class="tsy">* 申请过程中有任何疑问，请随时联系专属服务群内的专业文案老师为您解答护航。</div>
                    <el-checkbox v-model="optionChecked" label="我确认我所提供的材料真实有效，并知悉其中内容。" class="checkboxs" />
                </template>
                <div class="btn">
                    <span class="next-btn" @click="confirm">{{ readyPage === 2 ? "我已准备就绪，开始填写" : "下一步" }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import guide1 from '@/assets/img/guide/gifted1.png'
import guide2 from '@/assets/img/guide/gifted2.png'
import { setKey } from '../../api/gifted'
export default {
    name: "GiftedGuide",
    props: {
        isReady: {
            type: Number,
            default: null
        },
        readyPage: {
            type: Number,
            default: null
        },
    },
    data() {
        return {
            isLoading: false,
            guide1,
            guide2,
            optionChecked: false,
        }
    },
    created() {
        document.querySelector('body').classList.add("modal")
    },
    beforeDestroy() {
        document.querySelector('body').classList.remove("modal")
    },
    methods: {
        confirm() {
            if (!this.optionChecked && this.readyPage === 2) {
                return this.$message({
                    message: '请先勾选确认知悉内容',
                    type: 'error',
                });
            }
            // 第2页为最后一页，需要改变状态 不需要改变页码
            this.readyPage === 2 ? this.readInfo(1, this.readyPage) : this.readInfo(this.isReady, this.readyPage + 1)
        },

        /**
         * @description 保存阅读状态
         * @param {isReady} 是否阅读完 0否1是
         * @param {readyPage} 阅读到第几页
         */
        async readInfo(isReady, readyPage) {
            try {
                this.isLoading = true
                await setKey({
                    key: this.$store.state.gifted.user.userId,
                    value: JSON.stringify({ isReady, readyPage }),
                    option: this.optionChecked ? 1 : 0,
                    order_id: Number(this.$route.params.id),
                })
                // 判断阅读状态
                isReady === 1 ? this.$emit('update:isReady', 1) : this.$emit('update:readyPage', readyPage)
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        }
    },
}
</script>

<style lang="less" scoped>
.guide-box {
    z-index: 1000;
    background-color: rgba(0,0,0,.4);
    position: fixed;
    overflow: auto;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    .guide-content {
        border-radius: 15px;
        width: 1070px;
        margin: 10vh auto 50px;
        background-color: #fff;
        .guide-bg {
            position: relative;
            img {
                vertical-align: middle;
                width: 100%;
            }
            .close-btn {
                cursor: pointer;
                position: absolute;
                right: 20px;
                top: 20px;
                color: #fff;
                font-size: 30px;
                transition: .5s;
                &:hover {
                    transform: rotate(180deg);
                }
            }
        }
        .word-content {
            font-size: 22px;
            padding: 25px 65px;
            p {
                margin-bottom: 25px;
            }
            .btn {
                text-align: center;
                span.next-btn {
                    cursor: pointer;
                    display: inline-block;
                    border-radius: 5px;
                    color: #fff;
                    background-color: #009efe;
                    padding: 10px 40px;
                    font-size: 20px;
                    transition: .1s;
                    &:hover {
                        opacity: .8;
                    }
                }
            }
            .check-info {
                margin-bottom: 25px;
                display: flex;
                justify-content: space-between;
                i {
                    font-weight: bold;
                    color: #009efe;
                    margin-right: 5px;
                }
            }
            .msg {
                color: #007FF4;
                font-size: 22px;
                .msg-left {
                    font-weight: 600;
                }
            }
            .tsy {
                color: #000;
                font-size: 18px;
                margin-top: 15px;
            }
            .checkboxs {
                display: flex;
                align-items: center;
                margin-top: 35px;
                margin-bottom: 30px;
                /deep/ .el-checkbox__label {
                    font-size: 18px;
                    color: #000;
                }
            }
        }
    }
}
</style>
