<!--
 * @Author: zhai
 * @Description: 语言能力
-->
<template>
    <div>
        <el-form ref="userForm" :model="userForm" :rules="rules" label-width="100px">
            <!-- 语言能力 -->
           <Container title="语言能力">
                <div class="language-box">
                    <p class="language-p">您的语言水平与下列哪一项符合：</p>
                    <el-form-item label-width="0" prop="level">
                        <el-radio-group v-model="userForm.level">
                            <div v-for="item in languageList" :key="item.id">
                                <el-radio v-if="(userForm.school_address_aboard && item.id === 1) || item.id !== 1" :label="item.id">
                                    <div class="language-item">
                                        <div>
                                            <p>{{ item.tit }}</p>
                                            <div>{{ item.dec }}</div>
                                        </div>
                                        <el-form-item v-if="item.id === 4" prop="name" :rules="[{required: userForm.level === 4, message: '请输入中文之外的语种',trigger: 'blur'}]">
                                            <el-input v-model="userForm.name" style="width: 180px; margin-left: 10px" placeholder="请输入中文之外的语种" maxlength="30" clearable/>
                                        </el-form-item>
                                        <el-form-item v-if="item.id === 4" prop="remark" :rules="[{required: userForm.level === 4, message: '请输入2年内获取的证书及分数',trigger: 'blur'}]">
                                            <el-input v-model="userForm.remark" style="width: 240px; margin-left: 10px" placeholder="请输入2年内获取的证书及分数" maxlength="30" clearable/>
                                        </el-form-item>
                                    </div>
                                </el-radio>
                            </div>
                            
                        </el-radio-group>
                    </el-form-item>
                </div>
            </Container>
        </el-form>
    </div>
</template>

<script lang="javascript">
import datas from '../data'
export default {
    name: "Language",
    props: {
        userForm: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            languageList: datas.languageList,
            rules: {
                level: [{required: true, message: '请选择语言能力',trigger: 'change'}],
            }
        };
    },
    created() {
        console.log(this.userForm.level)
    },
    methods: {
        // 校验
        submitForm () {
            console.log(this.userForm)
            let that = this;
            return new Promise(function (resolve, reject) {
                that.$refs.userForm.validate(valid => {
                    if (valid) {
                        // if (that.userForm.level === 4 && that.userForm.name === "") {
                        //     that.$message.error("请输入中文之外的语种");
                        // }
                        // if (that.userForm.level === 4 && that.userForm.remark === "") {
                        //     that.$message.error("请输入2年内获取的证书及分数");
                        // }
                        if (that.userForm.level !== 4) {
                            that.userForm.remark = "";
                            that.userForm.name = "";
                            resolve(valid)
                        }
                        if (that.userForm.level === 4 && that.userForm.name && that.userForm.remark) {
                            resolve(valid)
                        }
                    } else {
                        reject();
                    }
                })
            })
        }
    },
};
</script>

<style lang="less" scoped>
.language-p {
    font-size: 14px;
    color: #303133;
    margin: 16px 0 17px 0;
    position: relative;
    padding-left: 12px;

    &::before {
        content: "*";
        position: absolute;
        left: 0;
        top: 2px;
        color: red;
    }
}

.language-box {
    padding: 0 16px;
    color: #606266;

    /deep/ .el-radio__label {
        white-space: initial;
        line-height: 20px;
    }

    .el-radio {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 17px;
    }

    p {
        margin-bottom: 4px;
    }
}

.language-item {
    display: flex;
    justify-content: center;
    align-items: center;
}
.language-box {
    /deep/ .el-radio__input {
        margin-top: 3px;
    }
}
</style>
