<!--
 * @Author: zhai
 * @Description: 居住信息
-->
<template>
    <div>
        <el-form ref="userForm" :model="userForm" label-width="100px">
            <!-- 居住信息 -->
            <Container title="居住信息(请按日期顺序列出你自出生后曾居住12个月及以上的所有国家或地区，包括现居、海外居住信息（如填写了海外工作经历请对应填写符合条件的信息）)" class="mb-20">
                <div v-for="(domain, idx) in userForm.residences" :key="domain.id" class="block-box">
                    <div class="live-box">
                        <div class="one-input">
                            <el-form-item :label="'国家'+ (idx + 1) + ':'" :prop="'residences.'+idx+'.country'" :rules="[{required: true,message: '请输入匹配国家及地区',trigger: 'blur'}]" style="width: 280px">
                                <!-- <el-input v-model.trim="domain.country" class="full-width" placeholder="请输入国家名称" maxlength="20" clearable /> -->
                                <CountrySelect v-model="domain.country" placeholder="请输入匹配国家及地区" style="width: 100%" />
                            </el-form-item>
                        </div>
                        <div class="residences-data">
                            <el-form-item label="开始时间:" :prop="'residences.'+idx+'.start_time'" :rules="[{required: true,message: '请选择开始时间',trigger: 'change'}]">
                                <el-date-picker v-model="domain.start_time" :picker-options="pickerBeginDateBefore" value-format="yyyy-MM" class="start-width" type="month" placeholder="请选择" @change="(value) => pikerStartTime(value, idx)" />
                            </el-form-item>
                            <el-form-item v-if="!domain.end_time_checked" label="结束时间:" :prop="'residences.'+idx+'.end_time'" :rules="[{required: !domain.end_time_checked, message: '请选择结束时间',trigger: 'change'}]">
                                <el-date-picker v-model="domain.end_time" :picker-options="pickerBeginDateBefore" value-format="yyyy-MM" class="end-width" type="month" placeholder="请选择" @change="(value) => pikerEndTime(value, idx)" />
                            </el-form-item>
                            <el-checkbox class="checked" v-model="domain.end_time_checked" @change="endTimeNow($event,domain)">至今</el-checkbox>
                        </div>
                        <div class="block-heard">
                            <el-popconfirm placement="top" title="确定要删除此工作经历吗？" @confirm="removeMemner(domain)">
                                <div v-if="idx!=0" slot="reference" class="del-cur">删除</div>
                            </el-popconfirm>
                        </div>
                    </div>
                </div>
                <div class="add-people" @click="addMember">+新增居住信息</div>
            </Container>
        </el-form>
    </div>
</template>

<script lang="javascript">
export default {
    name: "LivingInformation",
    props: {
        userForm: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            // 只能选择当天之前的日期
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() >= Date.now() - 8.64e6;
                },
            },
        };
    },
    created() {
        console.log(this.userForm)
    },
    methods: {
        // 禁止开始时间乱选
        pikerStartTime(e, idx) {
            let startDate = e;
            let endDate = this.userForm.residences[idx].end_time;
            if (endDate && !this.userForm.residences[idx].end_time_checked) {
                let result =
                    new Date(endDate).getTime() > new Date(startDate).getTime();
                if (!result) {
                    this.userForm.residences[idx].start_time = "";
                    this.$message.error("开始时间不得在结束时间之后");
                }
            }
        },
        // 禁止结束时间乱选
        pikerEndTime(e, idx) {
            let startDate = this.userForm.residences[idx].start_time;
            let endDate = e;
            if (startDate && !this.userForm.residences[idx].end_time_checked) {
                let result =
                    new Date(startDate).getTime() < new Date(endDate).getTime();
                if (!result) {
                    this.userForm.residences[idx].end_time = "";
                    this.$message.error("结束时间不得在开始时间之前");
                }
            }
        },
        // 至今选择
        endTimeNow(e, item) {
            if (e) {
                item.end_time = "";
            }
        },
        // 添加居住信息
        addMember() {
            this.userForm.residences.push({
                id: "",
                country: "",
                start_time: "",
                end_time: "",
                end_time_checked: false,
                key: Date.now(),
            });
        },
        // 删除居住信息
        removeMemner(item) {
            console.log(item)
            let index = this.userForm.residences.indexOf(item);
            if (index !== -1) {
                this.userForm.residences.splice(index, 1);
            }
        },
        // 校验
        submitForm () {
            let that = this;
            return new Promise(function (resolve, reject) {
                that.$refs.userForm.validate(valid => {
                    if (valid){
                        resolve(valid)
                    } else {
                        reject()
                    }
                })
            })
        }
    },
};
</script>

<style lang="less" scoped>
.one-input {
    box-sizing: border-box;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: relative;
}

.add-people {
    width: 928px;
    height: 35px;
    line-height: 35px;
    border: 1px solid #dcdfe6;
    color: #409eff;
    font-size: 14px;
    text-align: center;
    margin: 16px auto;
    cursor: pointer;
}

.block-box {
    padding-bottom: 6px;
}
.start-width {
    width: 130px;
}
.end-width {
    width: 130px;
}
.full-width {
    width: 150px;
}
.live-box {
    padding: 16px 16px 0 16px;
    display: flex;
    align-items:flex-start;
    > p {
        font-size: 14px;
        color: #606266;
        margin-bottom: 24px;
    }
}
.del-cur {
    cursor: pointer;
    color: #449ffc;
}
.block-heard {
    margin-left: 20px;
    padding-top: 5px;
}
.residences-data {
    display: flex;
    justify-content: center;
}
.checked {
    margin-top: 7px;
    margin-left: 10px;
}
</style>
