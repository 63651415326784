<!--
 * @Author: Jacob.Zhang
 * @Description: 优才
-->
<template>
    <div v-loading="isLoading" class="product-form">
        <!-- 步骤条 -->
        <el-steps :active="currentStep" align-center finish-status="success">
            <el-step v-for="(item, index) in titleList" :key="index"
                :description="item.title" :style="`cursor: ${currentStep < index ? 'default' : 'pointer'};`"
                @click.native="chooseStep(index)"
            >
                <template slot="title">
                    <el-progress type="circle" :percentage="parseInt(item.percentage)" :status="item.percentage === 100 ? 'success' : null" />
                </template>
            </el-step>
        </el-steps>
        <!-- 按需引入对应组件 -->
        <component :ref="currentItem.component" :is="currentItem.component" :current-item="currentItem"
            :loading.sync="isLoading" @update="getData(currentStep)" @next="getData(currentStep+1);"
        />
        <!-- 操作按钮 -->
        <div class="operations-btn" v-if="currentStep !== null">
            <el-button v-if="currentStep!==0" :loading="isLoading" @click="getData(currentStep-1)">上一步</el-button>
            <el-button v-if="currentStep !==  titleList.length - 1" :loading="isLoading" type="primary" @click="nextStep(currentItem.component, 1)">保存</el-button>
            <el-button :loading="isLoading" type="primary" @click="nextStep(currentItem.component, 2)">{{ currentStep ===  titleList.length - 1 ? '完成并提交资料' : '下一步' }}</el-button>
        </div>
        <!-- 引导页弹窗-->
        <GiftedGuide v-if="isReady === 0" :is-ready.sync="isReady" :ready-page.sync="readyPage" />
        <!-- 二维码弹窗 -->
        <show-code v-if="codeDialog" :show.sync="codeDialog" :data="codeData" @suc="codeDialogSuc" />
    </div>
</template>

<script>
import User from '../../../components/Form/Gifted/User/User.vue'
import Family from '../../../components/Form/Gifted/Family/Family.vue'
import Basics from '../../../components/Form/Gifted/Basics/Basics.vue'
import Qualification from '../../../components/Form/Gifted/Qualification/Qualification.vue'
import Work from '../../../components/Form/Gifted/Work/Work.vue'
import Other from '../../../components/Form/Gifted/Other/Other.vue'
import Done from '../../../components/Form/Gifted/Done/Done.vue'
import { getClientInfo, getKey, setKey, getNotifyStatus } from '../../../api/gifted'
import GiftedGuide from '../../../components/GuidePages/GiftedGuide.vue'
import ShowCode from '../../../components/ShowCode/ShowCode.vue'
export default {
    name: 'Gifted',
    components: {
        // 每个组件就是一个步骤
        User, Family, Basics, Qualification, Work, Other, Done,
        GiftedGuide,
        ShowCode
    },
    data() {
        return {
            isLoading: false,
            titleList: [], // 每一步的表单信息
            currentStep: null, // 当前到第几步了
            currentItem: {}, // 当前步骤的数据
            isReady: null, // 是否已经读完了 0和1（指导页）
            readyPage: null, // 读到了第几页
            codeDialog: false, //是否显示二维码弹窗
            codeData: {}, // 二维码弹窗数据
        }
    },
    created() {
        this.showCodeFun()
    },
    methods: {
        // 是否需要弹出
        async showCodeFun() {
            const { data } = await getNotifyStatus({})
            this.codeData = data
            if (data.status === 1) {
                // 需要显示弹窗
                this.codeDialog = true
            } else {
                // 不需要时
                this.codeDialog = false
                this.busFun()
            }
        },
        // 业务数据
        busFun() {
            this.isLoading = true
            Promise.all([this.getData(), this.getGuide()]).finally(() => {
                this.isLoading = false
            })
        },
        // 获取数据
        async getData(step = null) {
            if(step && step > this.titleList.length - 1) {
                this.$router.push({
                    name: "GiftedDetails",
                    params: {
                        id: Number(this.$route.params.id),
                    },
                })
                return
            }
            try {
                this.isLoading = true
                const res = await getClientInfo({
                    order_id: Number(this.$route.params.id),
                    step,
                })
                if(res.code === 200) {
                    if(step === this.currentStep + 1 || step === this.currentStep - 1) {
                        this.toTop();
                    }
                    this.currentStep = res.data.current_step
                    this.titleList = res.data.progress_bar
                    this.currentItem = {
                        order_id: Number(this.$route.params.id),
                        component: this.titleList.find((item, index) => index === this.currentStep).component,
                        ...this.titleList.find((item, index) => index === this.currentStep).data,
                    }
                }
            } catch (error) {
                console.log(error)
            } finally {
                this.isLoading = false
            }
        },
        // 获取指导页
        async getGuide() {
            try {
                const res = await getKey({ key: this.$store.state.gifted.user.userId, order_id: Number(this.$route.params.id), })
                if(res.code === 200 && Object.prototype.toString.call(res.data) === '[object Object]' && Object.keys(res.data).length === 0) {
                    // 先获取 如果为空就去设置
                    await setKey({
                        order_id: Number(this.$route.params.id),
                        key: this.$store.state.gifted.user.userId,
                        value: JSON.stringify({ isReady: 0, readyPage: 1 }) // 防止以后页数会增加或减少（传对象的话需要转成JSON字符串 否则获取数据时有问题）
                    })
                    // 设置成功后
                    this.isReady = 0
                    this.readyPage = 1
                } else {
                    // 有数据时
                    this.isReady = JSON.parse(res.data).isReady
                    this.readyPage = JSON.parse(res.data).readyPage
                }
            } catch (error) {
                console.log(error)
            }
        },
        // 选择某一步
        async chooseStep(curSelectIndex) {
            if(curSelectIndex >= this.currentStep) return // 不可以选当前及之后的步骤
            this.getData(curSelectIndex)
        },

        /**
         * @description 保存&下一步
         * @param {name} 组件名称
         * @param {handle} 1保存 2下一步
         */
        nextStep(name, handle) {
            this.$refs[name].checkInfo(handle)
        },
        toTop() {
            document.getElementsByTagName('html')[0].scrollIntoView({
                block: "start",
                behavior: "smooth",
            })
        },
        // 二维码弹窗回调 （进来需要先弹出二维码）
        codeDialogSuc() {
            this.busFun()
        }
    },
}
</script>

<style lang="less" scoped>

</style>
