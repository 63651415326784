<template>
    <div class="major">
        <Container class="mt-20" v-if="ruleForm.length === 0" title="主申请人专业资格及会员资格（如有）" style="height:45px;"></Container>
        <div v-for="(item, i) in ruleForm" :key="i">
            <Container :title="i===0?'主申请人专业资格及会员资格（如有）':'证书及会员资格'+(i+1)" :is-fillet="i === 0">
                <div slot="handle">
                    <el-popconfirm placement="top" title="确定删除？" @confirm="del(i)">
                        <el-button slot="reference" size="mini">删除</el-button>
                    </el-popconfirm>
                </div>
                <el-form :model="ruleForm[i]" :ref="'ruleForm' + i" label-width="130px">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item
                                label="资格证书名称："
                                :rules="{ required: true, message: '请选择资格证书', trigger: 'change' }"
                                prop="qualification"
                            >
                                <!-- <el-input v-model.trim="item.qualification" clearable maxlength="20" placeholder="请输入资格证书名称" /> -->
                                <el-select v-model="item.qualification" placeholder="请选择资格证书" style="width: 100%;">
                                    <el-option
                                        v-for="(item,i) in options"
                                        :key="i"
                                        :label="item"
                                        :value="item">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="颁授机构全称："
                                :rules="{ required: true, message: '请输入颁授机构全称', trigger: 'blur' }"
                                prop="college"
                            >
                                <el-input v-model="item.college" clearable maxlength="50" placeholder="颁授机构全名" />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="颁授年份："
                                :rules="{ required: true, message: '请选择毕业年月', trigger: 'change' }"
                                prop="promulgation"
                            >
                                <el-date-picker
                                    style="width: 100%;"
                                    v-model="item.promulgation"
                                    type="year"
                                    value-format="yyyy"
                                    :picker-options="pickerBeginDateBefore"
                                    placeholder="颁授年份">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-row>
                                <el-col :span="15">
                                    <el-form-item
                                        label=" 颁授城市："
                                        :rules="{ required: true, message: '请输入颁授城市', trigger: 'blur' }"
                                        prop="college_address.country"
                                    >
                                        <CountrySelect v-model="item.college_address.country" placeholder="输入匹配国家" @input="handleSelect($event, i)" />
                                    </el-form-item>
                                </el-col>
                                <el-col :span="9">
                                    <el-form-item
                                        class="form-item"
                                        v-if="item.college_address.country.indexOf('中国') !== -1 || item.college_address.country === ''"
                                        prop="college_address.area"
                                        :rules="{ required: true, message: '请选择城市', trigger: 'change' }"
                                    >
                                        <CitySelect :country="item.college_address.country" v-model="item.college_address.area"  style="width: 100%;" />
                                    </el-form-item>
                                    <el-form-item
                                        class="form-item"
                                        prop="college_address.foreign"
                                        :rules="{ required: true, message: '请输入城市', trigger: 'blur' }"
                                        v-else
                                    >
                                        <el-input v-model="item.college_address.foreign" clearable placeholder="请输入城市" />
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-col>
                    </el-row>
                </el-form>
            </Container>
        </div>
        <div class="add-box">
            <div class="add-btn" @click="addAducation">
                <el-button type="text">+新增资格证书</el-button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default:() => []
        }  
    },
    data() {
        return {
            // 只能选择当天之前的日期
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() >= Date.now() - 8.64e6;
                },
            },
            ruleForm: [],
            options: [
                'CFA(注册金融分析师)',
                'CFP(CFP证书是目前世界上权威的权威理财顾问认证项目之一) ',
                'FRM(金融风险管理师)',
                'FIA(保险精算师)',
                'CPA(注册会计师)',
                'CIIA(注册国际投资分析师)',
                'RFC(财务顾问师)',
                'ACCA(特许公认会计师)',
                'CIA(国际注册内部审计师)',
                'CMA(美国管理会计师)',
                'CQF(国际数量金融工程认证)',
                'CIMA(管理会计师考试)',
                'The Associate Chartered Accountant(英国皇家特许会计师)',
                'FCA(Fellow Chartered Accountant)'
            ]
        }
    },
    created() {
        if (this.data.length > 0) {
            this.ruleForm = this.data
        }
    },
    methods: {
        // 新增学历
        addAducation() {
            this.ruleForm.push({
                qualification: '',
                college: '',
                promulgation: '',
                college_address: {
                    details: '',
                    country: '',
                    area: [],
                    foreign: ''
                }
            })
        },
        // 校验参数
        checkForm() {
            const ruleFormArray = []
            this.ruleForm.forEach((item, i) => {
                ruleFormArray.push(this.$refs['ruleForm'+i][0].validate())
            })
            const isCheck = Promise.all(ruleFormArray)
            return isCheck
        },
        // 删除
        del(index) {
            this.ruleForm.splice(index, 1)
        },
        // 输入匹配
        handleSelect(v, index) {
            if (v.indexOf('中国') !== -1) {
                this.ruleForm[index].college_address_aboard = 0
            } else {
                this.ruleForm[index].college_address_aboard = 1
            }
            this.ruleForm[index].college_address.area = []
            this.ruleForm[index].college_address.foreign = ''
        },
    },
}
</script>

<style lang="less" scoped>
.add-box {
    background: white;
    padding: 20px;
    text-align: center;
    border: 1px solid #dcdfe6;
    border-top: 0 none;
    .add-btn {
        border: 1px dashed #ddd;
        padding: 3px 0;
        cursor: pointer;
    }
}
.form-item {
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
}
</style>
