<!--
 * @Author: zhai
 * @Description: 专业技能行业
-->
<template>
    <div>
        <el-form ref="userForm" :model="userForm" label-width="100px">
            <Container title="专业技能行业">
                <div class="industry-box">
                    <p class="language-p">1、最能代表你的专业技能的行业是（单选）</p>
                    <el-table ref="multipleTable" :data="industryList" style="width: 100%" :border="false" highlight-current-row @current-change="handleCurrentChange">
                        <el-table-column label="选项" width="50" align="center">
                            <template slot-scope="scope">
                                <el-radio v-model="scope.row.val" :label="scope.$index" />
                            </template>
                        </el-table-column>
                        <el-table-column label="编号" align="center" type="index" prop="messageTemplateBody" min-width="30" />
                        <el-table-column label="行业" prop="ind" min-width="40" />
                        <el-table-column label="例子" prop="example" min-width="100">
                            <template slot-scope="scope">
                                <span v-if="scope.row.example!=''">{{ scope.row.example }}</span>
                                <el-input v-else v-model.trim="tableRemake" maxlength="30" class="full-width" placeholder="请输入" clearable />
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </Container>
        </el-form>
    </div>
</template>

<script lang="javascript">
import { industryList } from '../../../../../api/gifted'
export default {
    name: "Industry",
    props: {
        userForm: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    data() {
        return {
            industryList: [],
            tableRemake: "",
        };
    },
    created() {
       this.init()
    },
    methods: {
        async init() {
            const { data } = await industryList()
            const industryListData = data.map(item => {
                return {
                    ind: item.name,
                    example: item.example,
                    val: "",
                    value: item.value,
                }
            })
            this.industryList = industryListData
            if (this.userForm.option !== 0) {
                this.industryList[Number(this.userForm.option) - 1].val = Number(this.userForm.option) - 1;
            }
            if (this.userForm.option === 15) {
                this.tableRemake = this.userForm.remark;
            }
        },

        // 单选最能代表你的专业技能的行业
        handleCurrentChange(val) {
            let num = this.industryList.indexOf(val);
            this.industryList.forEach((ele) => {
                ele.val = "";
            });
            this.industryList[num].val = num;
            if (num !== 14) this.tableRemake = "";
            this.userForm.option = num + 1;
        },
        save() {
            if (this.userForm.option === 15) {
                if (this.tableRemake) {
                    this.userForm.remark = this.tableRemake;
                }
            } else {
                this.userForm.remark = this.industryList[Number(this.userForm.option) - 1].example;
            }
        },
        // 校验
        submitForm () {
            let that = this;
            return new Promise(function (resolve, reject) {
                if (that.userForm.option === 0) {
                    that.$message.error("请选择专业技能行业");
                } else if (that.userForm.option === 15) {
                    if (that.tableRemake) {
                        that.userForm.remark = that.tableRemake;
                        resolve()
                    } else {
                        that.$message.error("请输入其他专业技能行业");
                    }
                } else {
                    that.userForm.remark = that.industryList[Number(that.userForm.option) - 1].example;
                    resolve()
                }
            })
        }
    },
};
</script>

<style lang="less" scoped>
.industry-box {
    padding: 0 16px;
    position: relative;

    /deep/.el-radio__label {
        display: none;
    }
}
.language-p {
    font-size: 14px;
    color: #606266;
}
</style>
