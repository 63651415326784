export default {
    languageList: [//语言能力
        {
            id: 1,
            tit: "中文及英文",
            dec: "国外大学（英语国家）毕业，英文授课，获得学位证。（如为中外联合办学，在中国上课的，不算）",
        },
        {
            id: 2,
            tit: "中文及英文",
            dec: " 有近两年的雅思或托福成绩单（雅思考试G类或A类总分达6分；托福笔试达550分，计算机达213分，网考达80分）",
        },
        {
            id: 4,
            tit: "中文及另一种外语",
            dec: "非英文的外语，如：法文、日文等（需提供相关证书）",
        },
        {
            id: 5,
            tit: "仅中文",
            dec: "",
        },
        {
            id: 6,
            tit: "仅英文",
            dec: "",
        },
    ],
    industryList: [ // 专业技能行业
        {
            ind: "学术研究及教育",
            example: "幼儿教育、高等教育、学术研究",
            val: "",
            value: 1,
        },
        {
            ind: "建筑、测量、工程及建造",
            example:
                "园景设计、建筑、测量、土木/电机/机器/机构/环境工程",
            val: "",
            value: 2,
        },
        {
            ind: "艺术及文化",
            example: "表演艺术、美术、博物馆、图书馆、摄影",
            val: "",
            value: 3,
        },
        {
            ind: "广播及娱乐",
            example:
                "广播及节目制作、电影、录像及电视制作、唱片及音乐出版",
            val: "",
            value: 4,
        },
        {
            ind: "业务支援及人力资源",
            example:
                "业务咨询顾问、公司秘书、行政支援、人事管理、招聘服务、公关关系服务、广告、信息服务",
            val: "",
            value: 5,
        },
        {
            ind: "餐饮服务及旅游",
            example: "酒吧、餐厅、酒店、旅行代理",
            val: "",
            value: 6,
        },
        {
            ind: "商业及贸易",
            example: "进出口、零售、批发",
            val: "",
            value: 7,
        },
        {
            ind: "金融及会计服务",
            example: "会计、银行、保险、证券、投资银行",
            val: "",
            value: 8,
        },
        {
            ind: "医疗保健及兽医服务",
            example:
                "西医药、中医药、牙科服务、医学科技、职业治疗、护理、兽医服务",
            val: "",
            value: 9,
        },
        {
            ind: "资讯科技及电讯",
            example: "资讯科技顾问、固网及无线通信",
            val: "",
            value: 10,
        },
        {
            ind: "法律服务",
            example: "法律事务",
            val: "",
            value: 11,
        },
        {
            ind: "物流运输",
            example: "物流、空运、海运、陆运",
            val: "",
            value: 12,
        },
        {
            ind: "工业制造",
            example:
                "电机、电子、食物及饮料、化工、金属、玩具、纺织、钟表、珠宝、印刷及出版",
            val: "",
            value: 13,
        },
        {
            ind: "体育运动",
            example: "体育运动",
            val: "",
            value: 14,
        },
        {
            ind: "其他",
            example: "",
            val: "",
            value: 15,
        },
    ],
}
