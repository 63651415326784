<template>
    <div class="qualification">
        <!-- 主申请人学历（请从大专开始写起） -->
        <education ref="education" :data.sync="currentItem.education" />
        <!-- 主申请人专业资格及会员资格（如有） -->
        <major ref="major" class="mt-20" :data="currentItem.qualification" />
        <!-- 学历证书姓名及出生日期正确性 -->
        <Correctness ref="correctness" class="mt-20" :data="currentItem.confirm" />
    </div>
</template>

<script>
import Education from './C/Education'
import Major from './C/Major'
import Correctness from './C/Correctness'
import { postClientInfo } from '../../../../api/gifted'
export default {
    props: {
        currentItem: {
            type: Object,
            default:() => {}
        },
        loading: {
            type: Boolean,
            default: false
        },
    },
    components: {
        Education,
        Major,
        Correctness
    },
    data() {
        return {
            ruleForm: {},
            rules: {}
        }
    },
    created() {
        
    },
    methods: {
        // 保存
        async saveProgressBar(type) {
            this.currentItem.education = this.$refs.education.ruleForm
            this.currentItem.qualification = this.$refs.major.ruleForm
            this.currentItem.confirm = this.$refs.correctness.ruleForm
            try {
                this.$emit('update:loading', true)
                const { code } = await postClientInfo(this.currentItem)
                if (code === 200) {
                    this.$message({
                        message: '保存成功',
                        type: 'success'
                    })
                    if (type === 0) {
                        this.$emit('update')
                    } else {
                        this.$emit('next')
                    }
                }
            } finally {
                this.$emit('update:loading', false)
            }
        },
        /**
         * @description 校验当前步骤的表单
         * @param {type} 1保存 2下一步
         */
        checkInfo(type) {
            // this.$emit('next')
            // return
            if(type === 1) {
                // 保存时不做校验
                // ajax请求
                this.currentItem.next = 0
                this.saveProgressBar(this.currentItem.next)
                return
            }

            // 点击的是下一步 校验成功后发送请求
            Promise.all([
                this.$refs.education.checkForm(),
                this.$refs.major.checkForm(),
                this.$refs.correctness.checkForm()
            ])
            .then(async () => {
                this.currentItem.next = 1
                this.saveProgressBar(this.currentItem.next)
                // console.log('通过验证')
                // 请求成功后 跳转
                // this.$emit('next')
            })
            .catch((err) => {
                console.log(err)
                this.$utils.scrollIntoError(this)
            })
        },
    },
}
</script>

