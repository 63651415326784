<template>
    <div class="mt-20">
        <Container v-if="ruleForm.length === 0" title="子女 - 随行信息" style="height:45px;"></Container>
        <div v-for="(item, i) in ruleForm" :key="i" :class="[i>0?'mt-20':'']">
            <Container :title="'子女'+(i+1)+'- 随行信息'">
                <div slot="handle">
                    <el-popconfirm placement="top" title="确定删除？" @confirm="del(i)">
                        <el-button slot="reference" size="mini">删除</el-button>
                    </el-popconfirm>
                </div>
                <el-form :model="ruleForm[i]" :ref="'ruleForm'+i" label-width="280px">
                    <el-row>
                        <el-col :span="12">
                            <el-form-item
                                label="主申人或现配偶是否拥有孩子抚养权："
                                prop="custody"
                                :rules="{ required: true, message: '请选择孩子抚养权', trigger: 'change' }"
                            >
                                <el-radio-group v-model="item.custody" @change="handleCustodyChange($event, i)">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="0">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label='出生日期：'
                                prop="birthday"
                                :rules="{ required: true, message: '请选择出生日期', trigger: 'change' }"
                            >
                                <el-date-picker
                                    style="width: 100%;"
                                    v-model="item.birthday"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="年 -月 -日"
                                    :picker-options="pickerBeginDateBefore"
                                    @change="hanldBirthdayClick($event, i)"
                                >
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item
                                label="该子女是否随行至香港："
                                prop="follow"
                                :rules="{ required: true, message: '请选择', trigger: 'change' }"
                            >
                                <el-radio-group v-model="item.follow" :disabled="(item.birthday ? hanldBirthdayClick(item.birthday, i, '1') >= 18 : false)">
                                    <el-radio :label="1">是</el-radio>
                                    <el-radio :label="2">否</el-radio>
                                </el-radio-group>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </Container>
            <!-- 国籍 -->
            <template v-if="item.follow === 1">
                <Container :is-fillet="false" :title="`子女${(i+1)} - 国籍`" red-font="请优先输入您的国籍，我们将根据您的国籍做证件的匹配">
                    <el-form :ref="'country'+i" :model="ruleForm[i]" label-width="150px">
                        <el-row>
                            <el-col :span="12">
                                <el-form-item
                                    label="国籍/地区："
                                    prop="nationality"
                                    :rules="{ required: true, message: '请输入选择', trigger: 'change' }"
                                >
                                    <CountrySelect v-model="item.nationality" style="width: 100%;" />
                                </el-form-item>
                            </el-col>
                            <el-col :span="12">
                                <el-form-item
                                    label="港澳通行证："
                                    prop="is_handled"
                                    :rules="{ required: true, message: '请选择', trigger: 'change' }">
                                    <el-select v-model="item.is_handled" placeholder="请选择" clearable style="width: 100%;">
                                        <el-option label="办理" :value="1" />
                                        <el-option label="未办理" :value="0" />
                                    </el-select>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </Container>
                <!-- 证件上传 -->
                <Container :is-fillet="false" v-if="item.nationality && item.is_handled !== ''" :title="'子女'+(i+1)+' - 证件'" red-font="可通过电脑上传证件，可为您节省该页40%的信息填写时间">
                    <CertificateUpload :ref="'CertificateUpload'+i"
                        :nationality="item.nationality" :is-handled="item.is_handled"
                        :certificates="item.certificates"  @handleInfo="getCertificate($event, i)"
                    />
                </Container>
            </template>
            <!-- 个人信息 -->
            <Container v-if="item.follow" :is-fillet="false" :title="'子女'+(i+1)+' - 个人信息'">
                <ChildrenInfo :ref="'ChildrenInfo'+i" :data.sync="ruleForm[i]"></ChildrenInfo>
            </Container>
            <template v-if="item.follow === 1">
                <Container :is-fillet="false" :title="'子女'+(i+1)+' - 中国身份证'" red-font="可上传图片识别">
                    <CnidForm :ref="'CnidForm'+i"
                        :card-info="item.certificates.cn_identity_card"
                        :required="item.nationality && item.nationality.includes('中国-') && hanldBirthdayClick($event, i) > 18"
                    />
                </Container>
                <Container :is-fillet="false" v-if="item.is_handled === 1" :title="'子女'+(i+1)+' - 港澳通行证'"  red-font="可上传图片识别">
                    <HkmPass :ref="'HkmPass'+i" :card-info="item.certificates.hk_macao_pass" :required="item.is_handled === 1" />
                </Container>
                <Container :is-fillet="false" :title="'子女'+(i+1)+' - 护照'"  red-font="暂不支持上传图片识别">
                    <Passport :ref="'Passport'+i"
                        :card-info="item.certificates.passport"
                        :required="item.nationality && !item.nationality.includes('中国-')"
                    />
                </Container>
            </template>
        </div>
        <div class="add-box">
            <div class="add-btn" @click="addChildren">
                <el-button type="text">+新增子女</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import CnidForm from '../../../../CnidForm/CnidForm.vue'
import HkmPass from '../../../../HkmPass/HkmPass.vue';
import Passport from '../../../../Passport/Passport.vue';
import ChildrenInfo from './ChildrenInfo.vue'
export default {
    props: {
        data: {
            type: Array,
            default: () => []
        }
    },
    components: {
        CnidForm,
        HkmPass,
        Passport,
        ChildrenInfo
    },
    data() {
        return {
            ruleForm: [],
            // 只能选择当天之前的日期
            pickerBeginDateBefore: {
                disabledDate(time) {
                    return time.getTime() >= Date.now() - 8.64e6;
                },
            }
        }
    },
    created() {
        if (this.data.length > 0) {
            this.data.forEach(item => {
                for(let key in item) {
                    if (item[key] == 100) {
                        item[key] = ''
                    }
                }
            })
            this.ruleForm = this.data
        }
    },
    watch: {
        ruleForm: {
            handler(newArr) {
                newArr.forEach((item, i) => {
                    if (item.follow == 1) {
                        const newVal = item.nationality
                        if(newVal && newVal.includes('中国-')) {
                            this.$refs['Passport'+i][0].clearCheck()
                        }else {
                            this.$refs['CnidForm'+i][0].clearCheck()
                        }
                    }
                })
            },
            deep: true
        }
    },
    methods: {
        // 新增子女
        addChildren() {
            this.ruleForm.push({
                custody: '',
                relation: 4,
                follow: '',
                subset_name: '',
                nationality: '',
                used_name: '',
                birthday: '',
                birth_place_aboard: 0,
                birth_place: {
                    area: [],
                    country: '',
                    details: '',
                    foreign: ''
                },
                occupation: '',
                occupation_name: '',
                child_has_hk_id: {
                    has: ''
                },
                hk_identity_card: '',
                sex: '',
                married: '',
                // 随行
                certificates: {
                    cn_identity_card: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    },
                    hk_macao_pass: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    },
                    passport: {
                        img_back: '',
                        img_front: '',
                        issue_at: '',
                        issue_date_end_at: '',
                        issue_date_start_at: '',
                        number: ''
                    }
                },
                nows: '',
                child_stepchild: {
                    has: ''
                },
                child_in_hk: {
                    has: '',
                    info: ''
                },
                electronic_visa_date: '',
                hk_identity: '',
                live_country: '',
                is_handled: '',
                address: {
                    area: [],
                    country: '',
                    details: '',
                    foreign: ''
                }
            })
        },
        // 是否满18周岁
        hanldBirthdayClick(time, i, type) {
            //获取出生年月日
            if (!time) {
                return ''
            }
            const yearDate = time.split('-')
            const yearBirth = yearDate[0]
            const monthBirth = yearDate[1]
            const dayBirth = yearDate[2]
            //获取当前年月日并计算年龄
            var myDate = new Date();
            var monthNow = myDate.getMonth() + 1;
            var dayNow = myDate.getDate();
            // console.log(myDate.getFullYear(), yearBirth)
            var age = myDate.getFullYear() - yearBirth;
            if(monthNow < monthBirth || (monthNow == monthBirth && dayNow < dayBirth)){
                age--;
            }
            if (!type) {
                if (age >= 18) {
                    this.ruleForm[i].follow = 2
                    this.ruleForm[i].married = ''
                } else {
                    this.ruleForm[i].married = '2'
                }
            }
            return age
        },
        // 删除
        del(index) {
            this.ruleForm.splice(index, 1)
        },
        // 孩子抚养权
        handleCustodyChange(v, i) {
            if (v == 0) {
                this.ruleForm[i].follow = 2
            }
        },
        // 校验参数
        checkParm() {
            const ruleFormArray = []
            this.ruleForm.forEach((item, i) => {
                ruleFormArray.push(this.$refs['ruleForm'+i][0].validate())
                if (item.follow) ruleFormArray.push(this.$refs['ChildrenInfo'+i][0].checkParm())
                if(item.follow === 1) {
                    ruleFormArray.push(this.$refs['country'+i][0].validate())
                    ruleFormArray.push(this.$refs['CnidForm'+i][0].checkInfo())
                    item.is_handled === 1 && ruleFormArray.push(this.$refs['HkmPass'+i][0].checkInfo())
                    ruleFormArray.push(this.$refs['Passport'+i][0].checkInfo())
                    // ruleFormArray.push(this.$refs['CertificateUpload'+i][0].checkInfo())
                } 
            })
            const isCheck = Promise.all(ruleFormArray)
            return isCheck
        },
        getCertificate(info, index) {
            // 图片
            this.ruleForm[index].certificates[info.type][`img_${info.id_card_side}`] = info.url
            // 判断证件类型
            switch (info.type) {
                case 'cn_identity_card':
                    if(info.id_card_side === 'front') {
                        this.ruleForm[index].certificates[info.type].number = info.number
                        if(!this.ruleForm[index].nationality.includes('中国-')) return
                        this.ruleForm[index].subset_name = info.username
                        // this.ruleForm[index].birthday = info.birthday
                        // this.hanldBirthdayClick(info.birthday, index)
                        this.ruleForm[index].sex = info.sex
                    }
                    if(info.id_card_side === 'back') {
                        this.ruleForm[index].certificates[info.type].issue_at = info.issue_at
                        this.ruleForm[index].certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.ruleForm[index].certificates[info.type].issue_date_end_at = info.issue_date_end_at
                    }
                    break;
                case 'hk_macao_pass':
                    if(info.id_card_side === 'front') {
                        this.ruleForm[index].certificates[info.type].number = info.number
                        this.ruleForm[index].certificates[info.type].issue_at = info.issue_at
                        this.ruleForm[index].certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.ruleForm[index].certificates[info.type].issue_date_end_at = info.issue_date_end_at
                    }
                    break;
                case 'passport':
                    if(info.id_card_side === 'front') {
                        this.ruleForm[index].certificates[info.type].number = info.number
                        this.ruleForm[index].certificates[info.type].issue_at = info.issue_at
                        this.ruleForm[index].certificates[info.type].issue_date_start_at = info.issue_date_start_at
                        this.ruleForm[index].certificates[info.type].issue_date_end_at = info.issue_date_end_at
                        if(this.ruleForm[index].nationality.includes('中国-')) return
                        this.ruleForm[index].subset_name = info.username
                        // this.ruleForm[index].birthday = info.birthday
                        // this.hanldBirthdayClick(info.birthday, index)
                        this.ruleForm[index].sex = info.sex
                    }
                    break;
                default:
                    break;
            }
        }
    }
}
</script>

<style lang="less" scoped>
.add-box {
    background: white;
    padding: 20px;
    text-align: center;
    border: 1px solid #dcdfe6;
    border-top: 0 none;
    .add-btn {
        border: 1px dashed #ddd;
        padding: 3px 0;
        cursor: pointer;
    }
}
.form-item {
    /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
}
</style>
